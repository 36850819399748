<template>
  <div id="schedule" class="schedule">
    <div
      class="container-fluid content pl-0 pr-0 pb-0 padding-top-responsive"
    >
      <div class="schedule-controller-wrapper col-12">
        <div class="row p-0 m-0 row-action-schedule">
          <v-col cols="12" class="pt-0 pb-0 wp-yard-schedule-day">
            <p class="yard-schedule-day m-0">{{ nowDate }} | {{ nowTime }}</p>
          </v-col>
          <v-col cols="5" class="col-xl-s6 schedule-courts" id="wp-court-menu">
            <div class="wp-select-court-menu">
              <v-text-field
                class="select-court-menu"
                v-model="selectedCourtName"
                readonly
                @click="toggleCourtMenu"
              >
                <template #prepend>
                  <span><i id="icon-caret-down" class="fas fa-caret-down"></i></span>
                </template>
              </v-text-field>
            </div>
            <div class="session-ktc-inside schedule-menu-none" id="responsive_Court">
              <div class="row pt-1">
                <div class="col-5 responsive-col-5 court-schedule">
                  <label class="yard-title">INDOOR SHOW COURT</label>
                  <div class="row m-0 menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      v-for="(item, index) in courts_data.indoor_show_group"
                      :key="index"
                      :class="{
                        'btn-yard-active':
                          activeCourt && item.id == activeCourt.id,
                        'mgl-8': index > 0,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(item)"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ item.name }}</span>
                    </button>

                    <!-- Menu item virtual IS4 on Ipad -->
                    <button
                      block
                      class="btn btn-yard rounded-0 mgl-8 virtual-menu-is4"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>IS4</span>
                    </button>
                    <!-- Menu item virtual IS4 on Ipad -->

                  </div>
                </div>

                <div class="col-7 responsive-col-7 court-schedule">
                  <label class="yard-title">INDOOR PRACTICE COURT</label>
                  <div class="row m-0 menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      v-for="(item, index) in courts_data.indoor_practice_group"
                      :key="index"
                      :class="{
                        'btn-yard-active':
                          activeCourt && item.id == activeCourt.id,
                        'mgl-8': index > 0,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(item)"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ item.name }}</span>
                    </button>
                  </div>
                </div>

                <div class="col-7 col-7-custom-schedule court-schedule" style="padding-top: 20px;">
                  <label class="yard-title">OUTDOOR TENNIS COURT</label>
                  <div class="row m-0 menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[0].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[0])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[0].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[1].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[1])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[1].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[2].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[2])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[2].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[3].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[3])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[3].name
                      }}</span>
                    </button>
                  </div>
                  <div class="row m-0 mt-3 menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[4].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[4])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[4].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[5].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[5])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[5].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[6].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[6])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[6].name
                      }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.outdoor_tennis_group[7].id ==
                            activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="
                        changeActiveCourt(courts_data.outdoor_tennis_group[7])
                      "
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{
                        courts_data.outdoor_tennis_group[7].name
                      }}</span>
                    </button>
                  </div>
                </div>

                <div class="col-2 col-2-custom-schedule court-schedule" style="padding-top: 20px;">
                  <label class="yard-title">JUNIOR</label>

                  <!-- Junior menu group on Desktop screen -->
                  <div class="row m-0 menu-view-desktop">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[0].name }}</span>
                    </button>
                  </div>
                  <div class="row m-0 mt-3 menu-view-desktop">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[1].name }}</span>
                    </button>
                  </div>
                  <!-- Junior menu group on Desktop screen -->

                  <!-- Junior menu group on Ipad screen -->
                  <div class="row m-0 menu-view-ipad menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[0].name }}</span>
                    </button>

                    <button
                      block
                      class="btn btn-yard rounded-0 mgl-8"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.junior[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.junior[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.junior[1].name }}</span>
                    </button>

                    <!-- Menu item virtual J3 and J4 on Ipad -->
                    <button
                      block
                      class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>J3</span>
                    </button>

                    <button
                      block
                      class="btn btn-yard rounded-0 mgl-8 virtual-menu-junior34"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>J4</span>
                    </button>
                    <!-- Menu item virtual J3 and J4 on Ipad -->

                  </div>
                  <!-- Junior menu group on Ipad screen -->

                </div>
                <div class="col-3 col-3-custom-schedule court-schedule" style="padding-top: 20px;">
                  <label class="yard-title">ROOFTOP COURT</label>

                  <!-- Rooftop menu group on Desktop screen -->
                  <div class="row m-0 menu-view-desktop">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[0].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[1].name }}</span>
                    </button>
                  </div>

                  <div class="row m-0 mt-3 menu-view-desktop">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[2].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[2])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[2].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[3].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[3])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[3].name }}</span>
                    </button>
                  </div>
                  <!-- Rooftop menu group on Desktop screen -->

                  <!-- Rooftop menu group on Ipad screen -->
                  <div class="row m-0 menu-view-ipad menu-space-between">
                    <button
                      block
                      class="btn btn-yard rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[0].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[0])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[0].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[1].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[1])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[1].name }}</span>
                    </button>

                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[2].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[2])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[2].name }}</span>
                    </button>
                    <button
                      block
                      class="btn btn-yard mgl-8 rounded-0"
                      :class="{
                        'btn-yard-active':
                          activeCourt &&
                          courts_data.rooftop_group[3].id == activeCourt.id,
                        'disable-schedule':
                          activeCourt && scheduleData && scheduleData.id,
                      }"
                      @click="changeActiveCourt(courts_data.rooftop_group[3])"
                      :style="{ width: width_btn_court + 'px' }"
                    >
                      <span>{{ courts_data.rooftop_group[3].name }}</span>
                    </button>
                  </div>
                  <!-- Rooftop menu group on Ipad screen -->

                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="7"
            class="col-xl-s6 schedule-time"
            style="padding-left: 0"
          >
            <div class="row margin-schedule-top">
              <div class="col-12 pt-0 pb-0 hour-schedule-responsive">
                <span class="yard-schedule" :class="!activeCourt || !activeCourt.name ? 'mb-3' : ''"
                  >Court:
                  <span class="yard-schedule-current" :class="!activeCourt || !activeCourt.name ? 'vertical-bottom-court' : '' ">
                    {{ activeCourt && activeCourt.name ? activeCourt.name : '--' }}</span
                  ></span
                >
              </div>
              <div class="col-10 pt-0 date-time-controller">
                <div class="session-ktc-inside" style="padding: 0;">
                  <div class="heading-schedule-info" style="border-bottom: 1px solid #B3B3B3; background-color: #777777;">
                    <button @click="createNewSchedule(false)" 
                      type="button" 
                      class="btn-lg w-50 rounded-0" 
                      :style="`${!scheduleData.checkRecurrence ? '' : 'background-color: #2c2c2c;'}`"
                    >
                      ONCE
                    </button>
                    <button @click="createNewSchedule(true)" 
                      type="button" 
                      class="btn-lg w-50 rounded-0" 
                      :style="`${scheduleData.checkRecurrence ? '' : 'background-color: #2c2c2c;'}`"
                    >
                      RECURRING
                    </button>
                  </div>

                  <div class="body-schedule-info">
                    <!-- Recurring Dates -->
                    <template v-if="scheduleData.checkRecurrence">
                      <div class="row row-info-schedule">
                        <div class="col-title-responsive col-4 pr-0 text-right padding-date-responsive">
                          <span class="schedule-label-current mt-1">DATE</span>
                        </div>
                        <div class="col-3 text-center m-auto padding-date-responsive date-menu-field date-picker-fromto">
                          <v-menu
                            ref="recurringPickerMenu"
                            v-model="recurringPickerMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="332px"
                            min-width="auto"
                            :disabled="disableDate()"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="input-date-picker-schedule"
                                v-model="dateRangeTextFrom"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="disableDate()"
                              >
                                <template #prepend>
                                  <i class="far fa-calendar-alt"></i>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              flat
                              v-model="scheduleData.start_end_dates"
                              no-title
                              range
                              width="auto"
                              first-day-of-week="0"
                              type="date"
                              :weekday-format="getDay"
                              :min="recurringMinDate"
                              :max="
                                NOW_DATE()
                                  .add(this.MAX_DAY_SELECT, 'days')
                                  .format(DATE_FORMAT_SCHEDULE)
                              "
                              :disabled="disableDate()"
                              class="v_date_picker_customize"
                              @input="closeRecurringPickerMenu"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                        <div class="schedule-label-to col-2">
                          <span class="schedule-label-current mt-1">TO</span>
                        </div>
                        <div class="col-3 text-center m-auto padding-date-responsive date-menu-field date-picker-fromto">
                          <v-text-field
                            class="input-date-picker-schedule"
                            v-model="dateRangeTextTo"
                            readonly
                            :disabled="disableDate()"
                            @click="openPickerMenu"
                          >
                            <template #prepend>
                              <i class="far fa-calendar-alt"></i>
                            </template>
                          </v-text-field>
                        </div>
                      </div>
                    </template>
                    <!-- End Recurring Dates -->

                    <!-- One-day Date -->
                    <template v-else>
                      <div
                        class="row row-info-schedule"
                        :class="{
                          checkDisabledEdit: scheduleData && scheduleData.id,
                        }"
                      >
                        <div class="col-title-responsive col-4 pr-0 text-right padding-date-responsive">
                          <span class="schedule-label-current mt-1">DATE</span>
                        </div>
                        <div class="col-input-responsive col-8 text-center m-auto padding-date-responsive date-menu-field">
                          <v-menu
                            ref="oneDayMenu"
                            v-model="oneDayMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="332px"
                            min-width="auto"
                            :disabled="disableDate()"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                class="input-date-picker-schedule"
                                v-model="scheduleData.dateText"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :disabled="disableDate()"
                              >
                                <template #prepend>
                                  <span v-html="renderCalendarIcon()"></span>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="pickerDate"
                              flat
                              no-title
                              width="auto"
                              class="v_date_picker_customize"
                              first-day-of-week="0"
                              type="date"
                              :weekday-format="getDay"
                              :min="NOW_DATE().format(DATE_FORMAT_SCHEDULE)"
                              :max="
                                NOW_DATE()
                                  .add(this.MAX_DAY_SELECT, 'days')
                                  .format(DATE_FORMAT_SCHEDULE)
                              "
                              :disabled="disableDate()"
                              @input="oneDayMenu = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </div>
                    </template>
                    <!-- End One-day Date -->

                    <div
                      class="row row-info-schedule"
                      :class="{
                        checkDisabledEdit: scheduleData && scheduleData.id,
                      }"
                    >
                      <div class="col-title-responsive col-4 pr-0 text-right">
                        <span class="schedule-label-current mt-1"
                          >START TIME</span
                        >
                      </div>
                      <div class="col-input-responsive col-8 text-center">
                        <b-form-timepicker
                          v-model="scheduleData.start_time"
                          locale="zh"
                          placeholder=""
                          :hour12="false"
                          @input="formatStartTime"
                          :disabled="disableStartTime() || disableFiveMinutesStart()"
                        ></b-form-timepicker>
                      </div>
                    </div>

                    <div class="row row-info-schedule">
                      <div class="col-title-responsive col-4 pr-0 text-right">
                        <span class="schedule-label-current mt-1">END TIME</span>
                      </div>
                      <div class="col-input-responsive col-8 text-center">
                        <b-form-timepicker
                          v-model="scheduleData.end_time"
                          locale="zh"
                          placeholder=""
                          :hour12="false"
                          @input="formatEndTime"
                          :disabled="disableEndTime() || disableFiveMinutesComplete()"
                        ></b-form-timepicker>
                      </div>
                    </div>

                    <div class="row row-info-schedule">
                      <div class="col-title-responsive col-4 pr-0 text-right">
                        <span class="schedule-label-current mt-1">MODE</span>
                      </div>
                      <div class="col-input-responsive col-8 text-center m-auto">
                        <v-select
                          class="custom-select-modes"
                          :items="scheduleData.modes ?? []"
                          filled
                          v-model="scheduleData.currentMode"
                          :disabled="!activeCourt || scheduleData.status == 'Completed' || disableFiveMinutesComplete()"
                          @click="updateModes()"
                        >
                        </v-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-2 pt-0 pl-0 wp-schedule-action">
                <div class="session-ktc-inside schedule-action">
                  <div
                    class="col-12 pt-1 text-center save-schedule mb-3"
                    :class="
                      !activeSaveSchedule() || disableFiveMinutesComplete() ? 'disable-schedule' : ''
                    "
                  >
                    <i
                      class="btn btn-save-schedule"
                      :title="scheduleData.checkRecurrence ? 'Save Recurring' : 'Save Schedule'"
                      @click="checkOptionSaveRecurring()"
                    ></i>
                  </div>
                  <div
                    class="col-12 pt-1 text-center remove-schedule mb-3"
                    :class="
                      !activeRemoveSchedule() || disableFiveMinutesComplete() ? 'disable-schedule' : ''
                    "
                  >
                    <i
                      class="btn btn-remove-schedule"
                      title="Remove Schedule"
                      @click="checkOptionRemoveRecurring()"
                    ></i>
                  </div>
                  <div
                    class="col-12 pb-0 pt-1 text-center"
                    :class="!activeCloseSchedule() ? 'disable-schedule' : ''"
                  >
                    <i
                      class="btn btn-close-schedule"
                      title="Cancel Schedule"
                      @click="resetForm()"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </div>
      </div>

      <div class="col-12 pb-0">
        <div
          class="row padding-responsive"
          v-if="
            dataSchedules && !dataSchedules.error && dataSchedules.length > 0
          "
        >
          <div class="col-2-grantt-chart">
            <div class="pre-week">
              <button class="btn-pre" @click="preNextWeek(-1)"></button>
            </div>
            <v-row class="pt-1">
              <div
                v-for="item in dataSchedules"
                :key="item.label"
                class="col-12 text-center font-size-21 day-in-week"
                :style="{ height: height_row + 'px' }"
                :class="{ 'to-day-row-label': classSelectedDate(item.date),
                  'day-passed-color': classDatePassed(item.date),
                }"
              >
                {{ item.label }}
              </div>
            </v-row>
            <div class="next-week pt-2">
              <button class="btn-next" @click="preNextWeek(1)"></button>
            </div>
          </div>
          <div class="col-10-grantt-chart">
            <g-gantt-chart
              ref="gantt-chart"
              :chart-start="myChartStart"
              :chart-end="myChartEnd"
              theme="dark"
              :grid="true"
              :hide-timeaxis="false"
              :push-on-overlap="false"
              precision="day"
              locale="en"
              :row-height="height_row"
              @dblclick-bar="eventClickBarMenu($event)"
              @dragend-bar="eventDragBar($event)"
              :highlighted-hours="HIGH_LIGHT_HOUR"
            >
              <g-gantt-row
                v-for="row in dataSchedules"
                :key="row.label"
                :label="row.label"
                :bars="row.bars"
                :highlight-on-hover="true"
                bar-start="myStart"
                bar-end="myEnd"
                :id="`${'row-grantt-' + row.date}`"
              >
                <template #bar-label="{ bar }">
                  <span :id="`${bar.recurring_id ? 'bar-active-' + bar.recurring_id + '-' + bar.date : 'bar-active-' + bar.id}`">{{ bar.mode }}</span>
                </template>
              </g-gantt-row>
            </g-gantt-chart>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="showModalContinuate"
      max-width="550"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog_content">
        <v-card-title style="background-color: #111111;"></v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content">
              <div class="text-center">
                <span>Changes are going to be discarded. Click</span><br>
                <span>confirm to discard changes or click return to</span><br>
                <span>return to editing screen</span>
              </div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="acceptAction()"
              >
                CONFIRM
              </button>
              <button
                class="btn btn-primary ml-1 btn_black"
                :ripple="false"
                @click="cancelAction()"
              >
                RETURN
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalRemove"
      max-width="550"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog_content">
        <v-card-title style="background-color: #111111;"></v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content">
              <div v-html="messageDialogRemoveSchedule"></div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="removeSchedule"
              >
                Yes
              </button>
              <button
                class="btn btn-primary ml-1 btn_black"
                :ripple="false"
                @click="showModalRemove = false"
              >
                No
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showModalRecurringEvent"
      max-width="400"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog_content">
        <v-card-title style="background-color: #111111;">{{recurring_title}}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content" style="padding-top: 6px; text-align: left;">
              <label>
                <input
                  value="this_event"
                  type="radio"
                  class="color-input-pick"
                  name="recurring-event"
                  v-model="scheduleData.option"
                />
                This event
              </label>
              <label :style="disableOptions() || disableOptionsDraggable() ? 'opacity: 0.3' : ''">
                <input
                  value="this_and_following_events"
                  type="radio"
                  class="color-input-pick"
                  name="recurring-event"
                  :disabled="disableOptions() || disableOptionsDraggable()"
                  v-model="scheduleData.option"
                />
                This and following events
              </label>
              <label :style="disableOptions() || disableOptionsDraggable() ? 'opacity: 0.3' : ''">
                <input
                  value="all_events"
                  type="radio"
                  class="color-input-pick"
                  name="recurring-event"
                  :disabled="disableOptions() || disableOptionsDraggable()"
                  v-model="scheduleData.option"
                />
                All events
              </label>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                v-if="isEditRecurring"
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="saveSchedule"
              >
                Ok
              </button>
              <button
                v-else-if="isDragRecurring"
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="selectRecurringOption"
              >
                Ok
              </button>
              <button
                v-else
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="showModelRemove"
              >
                Ok
              </button>
              <button
                class="btn btn-primary ml-1 btn_black"
                :ripple="false"
                @click="closeModalRecurringEvent"
              >
                Cancel
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalHolidayConfirm"
      max-width="550"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog_content">
        <v-card-title style="background-color: #111111;"></v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content" style="padding-top: 6px">
              <div class="text-center" v-html="messageDialogHolidayConfirm"></div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary mr-1 btn_black"
                :ripple="false"
                @click="IncludeHoliday"
              >
                Yes
              </button>
              <button
                class="btn btn-primary ml-1 btn_black"
                :ripple="false"
                @click="NoIncludeHoliday"
              >
                No
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ModalMessage ref="modalMessage"></ModalMessage>
  </div>
</template>

<style lang="scss" src="../../assets/css/schedule.scss"></style>

<script>
import { GGanttChart, GGanttRow } from "vue-ganttastic";
import { mapGetters } from "vuex";
import { scheduleService, holidayService, } from "../../services";
import courts_data_default from "@/components/courts_data";
import ModalMessage from "@/components/ModalMessage.vue";
import moment from "moment";
const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export default {
  name: "ScheduleManagement",
  components: {
    GGanttChart,
    GGanttRow,
    ModalMessage,
  },
  data() {
    return {
      height_row: 68,
      myChartStart: moment()
        .startOf("day")
        .format(this.DATE_TIME_HOUR_FORMAT_MOMENT),
      myChartEnd: moment()
        .add(1, "days")
        .startOf("day")
        .format(this.DATE_TIME_HOUR_FORMAT_MOMENT),
      start_date_schedule: moment().startOf("week"),
      end_date_schedule: moment().endOf("week"),
      date_between: [],
      courts_data: courts_data_default,
      courts: courts_data_default.data,
      activeCourt: null,
      scheduleData: {
        date: null,
        dateText: null,
        start_time: "",
        end_time: "",
        modes: [],
        currentMode: null,
        id: null,
        status: 'New',
        type: 'new',
        checkRecurrence: false,
        start_end_dates: [],
        option: "this_event",
        recurring_id: null,
        holiday: false,
      },
      showModalRemove: false,
      showModalContinuate: false,
      showModalRecurringEvent: false,
      messageDialogRemoveSchedule: null,
      width_btn_court: 84,
      nowTime: "",
      nowDate: "",
      nowDateTime: moment(),
      activeNew: false,
      waitLoadingData: false,
      date: moment().format(this.DATE_FORMAT_SCHEDULE),
      groupModes: {},
      interval_nowTimes: null,
      activeClick: null,
      courtChange: null,
      dataScheduleEvent: null,
      menuStartTime: false,
      menuEndTime: false,
      recurring_title: "",
      isEditRecurring: false,
      isDragRecurring: false,
      holidayExistData: null,
      showModalHolidayConfirm: false,
      messageDialogHolidayConfirm: false,
      activeRecurring: true,
      oneDayMenu: false,
      recurringPickerMenu: false,
      today: moment().format('YYYY-MM-DD'),
      currentTime: moment().format('YYYY-MM-DD'),
      selectedCourtName: "− −",
    };
  },
  watch: {
    'currentTime' (val) {
      if(this.today < val) {
        this.today = val;
        this.setBackgroundActiveRowChart(this.today);
      }
    }
  },
  computed: {
    ...mapGetters({ getDataSchedules: "schedules/getDataSchedules" }),
    dataSchedules: {
      get() {
        return this.getDataSchedules;
      },
      set(newData) {
        return newData;
      },
    },
    pickerDate: {
      get: function () {
        return this.date;
      },
      set: function (newDate) {
        this.date = moment(newDate).format(this.DATE_FORMAT_SCHEDULE);
        this.scheduleData.date = newDate;
        this.scheduleData.dateText = moment(newDate).format("ddd, DD MMM YY");
        this.start_date_schedule = moment(newDate).clone().startOf("week");
        this.end_date_schedule = moment(newDate).clone().endOf("week");
        if(this.activeClick != 'schedule-db-click') {
          this.getListRecurring();
        }
      },
    },
    dateRangeTextFrom () {
      if(this.scheduleData.start_end_dates && this.scheduleData.start_end_dates.length > 0){
        return `${this.scheduleData.start_end_dates[0] ? moment(this.scheduleData.start_end_dates[0]).format("ddd, DD MMM YY") : ''}`;
      }
      return null;
    },
    dateRangeTextTo () {
      if(this.scheduleData.start_end_dates && this.scheduleData.start_end_dates.length > 1){
        return `${this.scheduleData.start_end_dates[1] ? moment(this.scheduleData.start_end_dates[1]).format("ddd, DD MMM YY") : ''}`;
      }
      return null;
    },
    recurringMinDate() {
      const nowDate = this.NOW_DATE().format(this.DATE_FORMAT_SCHEDULE);
      if (this.scheduleData?.start_end_dates?.length === 1) {
        return this.scheduleData.start_end_dates[0];
      }
      return nowDate;
    },
  },
  async created() {
    this.date = moment().format(this.DATE_FORMAT_SCHEDULE);
    await this.getListModes();
    await this.getListCourts();
    await this.getListRecurring();
    this.$forceUpdate();
  },
  mounted() {
    this.nowTimes();
    this.interval_nowTimes = setInterval(function () { this.nowTimes(); }.bind(this), 1000);
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
    this.$store.dispatch("schedules/setScheduleIndexFunc", this);
    setTimeout(() => {
      this.setBackgroundActiveRowChart(this.today);
    }, 500);
  },
  destroyed() {
    clearInterval(this.interval_nowTimes);
  },
  methods: {
    openPickerMenu() {
      return this.recurringPickerMenu = !this.recurringPickerMenu;
    },
    getDay(date){
      let i = new Date(date).getDay(date)
      return daysOfWeek[i]
    },
    toggleCourtMenu() {
      let collapse = document.getElementById("responsive_Court");
      let icon_rotate = document.getElementById("icon-caret-down");
      if(collapse && icon_rotate) {
        collapse.classList.toggle("open-menu");
        icon_rotate.classList.toggle("rotate-caret-down");
      }
    },
    setBackgroundInactiveRow() {
      let inactiveRow = document.querySelector('.row-grantt-today');
      if (inactiveRow) {
        inactiveRow.classList.remove('row-grantt-today');
      }
    },
    setBackgroundActiveRowChart(dayNow){
      this.setBackgroundInactiveRow();
      let elementRowId = 'row-grantt-' + dayNow;
      let activeRowId = document.getElementById(elementRowId);
      if (activeRowId) {
        activeRowId.classList.add("row-grantt-today");
      }
    },
    formatStartTime() {
      if (this.scheduleData.start_time) {
        this.scheduleData.start_time = this.scheduleData.start_time.substring(0, 5);
      }
    },
    formatEndTime() {
      if(this.scheduleData.end_time){
        this.scheduleData.end_time = this.scheduleData.end_time.substring(0, 5);
      }
    },
    setBackgroundInactiveBar() {
      let inactiveBar = document.querySelector('.background-highlighted');
      if (inactiveBar) {
        inactiveBar.classList.remove('background-highlighted');
      }
    },
    setBackgroundActiveBar(activeBar) {
      let elementBarId = activeBar.recurring_id ? 'bar-active-' + activeBar.recurring_id + '-' + activeBar.date : 'bar-active-' + activeBar.id;
      let activeBarId = document.getElementById(elementBarId);
      if (activeBarId) {
        let activeParentBar = activeBarId.parentNode;
        if (activeParentBar) {
          activeParentBar.classList.add("background-highlighted");
        }
      }
    },
    closeRecurringPickerMenu () {
      if(this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[0] && this.scheduleData.start_end_dates[1]){
        this.recurringPickerMenu = false;
      }else{
        this.recurringPickerMenu = true;
      }
      return this.recurringPickerMenu;
    },
    async closeModalRecurringEvent() {
      await this.getListRecurring();
      this.showModalRecurringEvent = false;
      this.isEditRecurring = false;
    },
    renderCalendarIcon(){
      if(!this.isEditSchedule()){
        return `<i class="far fa-calendar-alt"></i>`;
      }
      return null;
    },
    isEditSchedule() {
      return this.scheduleData && this.scheduleData.status && (this.STATUS_DISABLED_DATE_SCHEDULE.includes(this.scheduleData.status))
    },
    disableDate() {
      return !this.activeCourt || this.isEditSchedule();
    },
    disableStartTime() {
      return !this.activeCourt ||
        (this.scheduleData && this.scheduleData.status && this.scheduleData.status == 'On-going') 
        || this.scheduleData.status == 'Completed'
    },
    disableFiveMinutesComplete(){
      let myEndTime = moment(this.scheduleData.checkMyEnd);
      let myNowTime = moment();
      let fiveMinutesComplete = (moment().add(5, 'minutes'));
      return this.scheduleData.status === 'On-going' && myEndTime.isBetween(myNowTime, fiveMinutesComplete)
    },
    disableFiveMinutesStart(){
      let myStartTime = moment(this.scheduleData.checkMyStart);
      let myNowTime = moment();
      let fiveMinutesComplete = (moment().add(5, 'minutes'));
      return this.scheduleData.status === 'Pending' && myStartTime.isBetween(myNowTime, fiveMinutesComplete)
    },
     disableEndTime() {
      return !this.activeCourt || this.scheduleData.status == 'Completed'
    },
    activeNewSchedule() {
      return this.activeCourt && (this.scheduleData.date || this.scheduleData.start_end_dates) && !this.scheduleData.id && this.scheduleData.status == 'New' && !this.activeNew;
    },
    activeSaveSchedule() {
      return this.activeCourt && (this.scheduleData.date || this.scheduleData.start_end_dates?.length === 2) && this.scheduleData.status != 'Completed';
    },
    activeRemoveSchedule() {
      return this.activeCourt && this.scheduleData.date && this.scheduleData.status != 'Completed' && (this.scheduleData.status == 'On-going' || this.scheduleData.status == 'Pending');
    },
    activeCloseSchedule() {
      return this.activeCourt && (this.scheduleData.date || this.scheduleData.start_end_dates);
    },
    classSelectedDate(dayRow) {
      return (this.pickerDate ?? moment().format(this.DATE_FORMAT_SCHEDULE)) == dayRow;
    },
    classDatePassed(dayRow) {
      return moment().format(this.DATE_FORMAT_SCHEDULE) > dayRow;
    },
    async getListModes() {
      await this.$store.dispatch("courts/getModes");
      this.groupModes = await this.handelGroupModes(
        this.$store.state.courts.modes
      );
    },
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts");
      this.courts = this.$store.state.courts.courts;
      this.courts_data = await this.handelGroupCourts(
        this.groupModes,
        this.courts
      );
    },
    nowTimes() {
      this.nowDate = moment().format("ddd, DD MMM YYYY");
      this.nowTime = moment().format(this.HOUR_FORMAT);
      this.nowDateTime = moment();
      this.currentTime = moment().format('YYYY-MM-DD');
    },
    changeActiveCourt(court) {
      this.courtChange = court;
      this.selectedCourtName = "COURT " + this.courtChange.name;
      if (this.activeNew && this.activeCourt && this.activeCourt.id != court.id && this.scheduleData.date && this.scheduleData.status == 'New') {
        this.activeClick = 'court';
        this.showModalContinuate = true
      } else {
        this.setActiveCourt();
      }
      setTimeout(() => {
        this.setBackgroundActiveRowChart(this.today);
      }, 200);
    },
    async setActiveCourt() {
      this.activeCourt = this.courtChange;
      this.scheduleData.modes = this.activeCourt.modes;
      this.courtChange = null;
      await this.getListRecurring();

      this.activeNew = true;
      this.scheduleData.status = 'New';
      this.scheduleData.currentMode = null;
    },
    cancelAction() {
      this.showModalContinuate = false;
      this.courtChange = null;
      if (this.activeClick == 'schedule-drag') {
        this.revertBar(this.dataScheduleEvent);
      }
      this.activeClick = null;
      this.dataScheduleEvent = null;
    },
    revertBar(data) {
      let now = moment().startOf("day");
      let indexRow = this.dataSchedules.findIndex((item) => {
        return item.date == data.date;
      });
      if (indexRow >= 0) {
        let indexBar = this.dataSchedules[indexRow].bars.findIndex((item) => {
          return item.id == data.id;
        });
        if (indexBar >= 0) {
          this.setTimeBar(indexRow, indexBar, now, now, data.start_time, data.end_time);
        }
      }
    },
    async acceptAction() {
      if (this.activeClick == 'court') {
        await this.setActiveCourt();
      } else if (this.activeClick == 'schedule-db-click') {
        await this.clickBarMenu();
      } else if (this.activeClick == 'schedule-drag') {
        await this.stoppedDraggingBar();
      } else if (this.activeClick == 'change-page') {
        let toPath = this.$store.state.schedules.toPath;
        window.location.href = toPath ?? '/';
      }
      this.showModalContinuate = false;
    },
    preNextWeek(week) {
      let currentWeek = moment(this.start_date_schedule).add(week, "weeks");
      let max = this.NOW_DATE().clone().add(this.MAX_DAY_SELECT, "days");
      let daysBetween = this.getDaysBetween(currentWeek.clone().startOf("week"), currentWeek.clone().endOf("week"));
      let check_result = daysBetween.filter((item) => { return item <= max; });
      let vm = this;
      if (check_result && check_result.length > 0) {
        vm.start_date_schedule = currentWeek.clone().startOf("week");
        vm.end_date_schedule = currentWeek.clone().endOf("week");
        vm.getListRecurring();
      }
    },
    getStyleBar(bar) {
      if (this.nowDateTime.isBetween(moment(bar.start_time), moment(bar.end_time))) {
        return { color: "#212121", backgroundColor: this.COLOR_SCHEDULE_ONGOING };
      }
      return { color: "#FFFFFF", backgroundColor: this.COLOR_SCHEDULE_PENDING };
    },
    async getListRecurring() {
      this.waitLoadingData = true;
      let data = [];
      if (this.activeCourt && this.activeCourt.id) {
        let params =  "?start_time=" + this.start_date_schedule.startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT) +
          "&end_time=" + this.end_date_schedule.endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT)
         + '&group_id=' + this.activeCourt.id;
        data = await this.$store.dispatch("schedules/getSchedules", params);
      }
      if (data.error) {
        this.$refs.modalMessage.open(data.message);
      } else {
        await this.customSchedules(data);
      }
      this.waitLoadingData = false;
    },
    async customSchedules(dataSchedules) {
      let vm = this;
      let daysBetween = this.getDaysBetween(this.start_date_schedule, this.end_date_schedule);
      await vm.$store.dispatch("schedules/customDataSchedules", { dataSchedules: dataSchedules, daysBetween: daysBetween });
    },
    checkDisabledEdit() {
      let start_date = moment(
        moment(this.scheduleData.date).format(this.DATE_FORMAT_SCHEDULE) +  " " + this.scheduleData.start_time + ":00");
      let end_date = 
        moment(moment(this.scheduleData.date).format(this.DATE_FORMAT_SCHEDULE) +  " " + this.scheduleData.end_time + ":00");
      if (start_date <= moment() && end_date > moment()) {
        return "disable-schedule";
      }
      return "";
    },
    checkTimeInvalid(data) {
      let start_time = moment(data.start_time);
      let end_time = moment(data.end_time);
      let now_time = this.NOW_DATE();
      let now_after_5 = this.NOW_DATE().add(5, 'minutes');
      let check_start_hour = moment(data.start_time).format("HH:mm");
      let check_end_hour = moment(data.end_time).format("HH:mm");
      if (!data.start_time || !data.end_time || !data.mode) {
        this.$refs.modalMessage.open( "Please enter a valid start time, end time, and dimming mode.");
        return false;
      }
      if (check_start_hour >= check_end_hour) {
        this.$refs.modalMessage.open( "Please set the end time to be later than start time.");
        return false;
      } else if (start_time < moment().add(5, 'minutes') && !(start_time.isBetween(now_time, now_after_5)) && !(this.NOW_DATE().isBetween(start_time, end_time))) {
        this.$refs.modalMessage.open( "Start time as early as 5 minutes later from now.");
        return false;
      } else if (end_time < moment().add(5, 'minutes') && !(end_time.isBetween(now_time, now_after_5)) && this.scheduleData.status === 'On-going' && this.NOW_DATE().isBetween(start_time, end_time)) {
        this.$refs.modalMessage.open( "End time must be 5 minutes from now.");
        return false;
      } else if (end_time < moment().add(5, 'minutes') && this.scheduleData.status === 'Pending' && start_time.isBetween(now_time, now_after_5)) {
        this.$refs.modalMessage.open( "End time must be 5 minutes from now.");
        return false;
      } else if (end_time <= moment()) {
        this.$refs.modalMessage.open( "End time must be greater than now.");
        return false;
      } else if (data.id && end_time <= moment()) {
        this.$refs.modalMessage.open("Schedule has been completed. Can not change!");
        return false;
      }
      return true;
    },
    updateModes() {
      if(!this.scheduleData?.modes?.length) {
        this.scheduleData.modes = this.activeCourt.modes;
        this.$forceUpdate();
      }
    },
    resetForm(clearCourt = false) {
      this.scheduleData.id = null;
      this.scheduleData.start_time = "";
      this.scheduleData.end_time = "";
      this.scheduleData.modes = [];
      this.scheduleData.currentMode = null;
      this.scheduleData.recurring_id = null;
      this.scheduleData.status = 'New';
      this.activeNew = false;
      this.scheduleData.date = null;
      this.scheduleData.dateText = null;
      if(this.scheduleData.start_end_dates?.length >= 1) {
        this.scheduleData.start_end_dates = [];
      }
      this.date = null;
      if (clearCourt) {
        this.activeCourt = null;
      }
      this.cancelAction();
      this.setBackgroundInactiveBar();
      setTimeout(() => {
        this.setBackgroundActiveRowChart(this.today);
      }, 200);
    },
    createNewSchedule(recurrence) {
      this.scheduleData.checkRecurrence = recurrence;
      this.resetForm();
    },
    async addSchedule(data) {
      if(this.scheduleData.checkRecurrence){
        await scheduleService.storeRecurring(data).then((res) => {
            if (res.status !== 200 || (res.data && !res.data.success)) throw res;
            this.$refs.modalMessage.open(res.data.message);
            this.resetForm();
          })
          .catch((err) => {
            if (err?.response?.data?.message) {
              this.$refs.modalMessage.open(err.response.data.message);
            }
          });
        await this.getListRecurring();
      }else{
        await scheduleService.storeSchedule(data).then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
          let storeMessage = res.data.message ? 'Schedule is saved successfully.' : '';
          this.$refs.modalMessage.open(storeMessage);
          this.resetForm();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            if(err.response.data.message === "Start time must be greater than 5 minutes from now."){
              this.$refs.modalMessage.open("Please set start time or end time to be at least 5 minutes from now.");
            }else{
              this.$refs.modalMessage.open(err.response.data.message);
            }
          }
        });
        await this.getListRecurring();
      }
    },
    async checkHolidayExist(item){
      await holidayService.checkHolidayExist(item).then((res) => {
        if (res.status !== 200) throw res;
        const data = res.data.data;
        this.holidayExistData = data;
      })
      .catch((err) => {
        console.log(err)
      });
    },
    async startAddSchedule(){
      if(this.dataScheduleEvent){
        let dataDraggable = this.dataScheduleEvent;
        let _e_start = moment(dataDraggable.myStart);
        let _e_end = moment(dataDraggable.myEnd);
        let dataEvent = {
          group_id: dataDraggable.group_id,
          date: moment(dataDraggable.date).format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          start_time: dataDraggable.date + " " + _e_start.format(this.HOUR_FORMAT) + ":00",
          end_time: dataDraggable.date + " " + _e_end.format(this.HOUR_FORMAT) + ":00",
          mode: dataDraggable.mode,
          recurring_id: dataDraggable.recurring_id ? dataDraggable.recurring_id : null,
          option: this.scheduleData.option,
          holiday: this.scheduleData.holiday,
          id: dataDraggable.id ? dataDraggable.id : null,
        };
        await this.update(dataEvent);
      }else{
        let _start_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[0] ? moment(this.scheduleData.start_end_dates[0]) : '';
        let _end_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[1] ? moment(this.scheduleData.start_end_dates[1]) : '';
        let _date = this.scheduleData.date ? moment(this.scheduleData.date) : '';
        let data = {
          group_id: this.activeCourt.id,
          date: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) : _date.format(this.DATE_FORMAT_SCHEDULE),
          start_time: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00",
          end_time: this.scheduleData.checkRecurrence ? _end_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00",
          mode: this.scheduleData.currentMode,
          recurring_id: this.scheduleData.recurring_id ? this.scheduleData.recurring_id : null,
          option: this.scheduleData.option,
          holiday: this.scheduleData.holiday,
          id: this.scheduleData.id ? this.scheduleData.id : null,
        };
        if(this.scheduleData.status !== 'New'){
          await this.update(data);
        }else{
          await this.addSchedule(data);
        }
      }
    },
    async IncludeHoliday(){
      this.scheduleData.holiday = true;
      await this.startAddSchedule();
      this.showModalHolidayConfirm = false;
    },
    async NoIncludeHoliday() {
      this.scheduleData.holiday = false;
      if(this.dataScheduleEvent){
        let dataDraggable = this.dataScheduleEvent;
        let _e_start = moment(dataDraggable.myStart);
        let _e_end = moment(dataDraggable.myEnd);
        let dataEvent = {
          group_id: dataDraggable.group_id,
          date: moment(dataDraggable.date).format(this.FULL_DATE_TIME_FORMAT_MOMENT),
          start_time: dataDraggable.date + " " + _e_start.format(this.HOUR_FORMAT) + ":00",
          end_time: dataDraggable.date + " " + _e_end.format(this.HOUR_FORMAT) + ":00",
          mode: dataDraggable.mode,
          recurring_id: dataDraggable.recurring_id ? dataDraggable.recurring_id : null,
          option: this.scheduleData.option,
          holiday: this.scheduleData.holiday,
          id: dataDraggable.id ? dataDraggable.id : null,
        };
        await this.update(dataEvent);
      }else{
        if(this.scheduleData.checkRecurrence || this.scheduleData.recurring_id){
          await this.startAddSchedule();
        }else{
          await this.getListRecurring();
        }
      }
      this.showModalHolidayConfirm = false;
    },
    async getSchedulesInDay(date) {
      let schedulesInDay = [];
      if (this.activeCourt && this.activeCourt.id) {
        let params = "?start_time=" + date.clone().startOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT) +
          "&end_time=" + date.clone().endOf("day").format(this.FULL_DATE_TIME_FORMAT_MOMENT)
          + "&group_id=" + this.activeCourt.id;
        await scheduleService.getListRecurring(params).then((res) => {
          if (res.status == 200 && res.data.success) {
            schedulesInDay = res.data.data;
          }
        });
      }
      return schedulesInDay;
    },
    checkInvalidData() {
      let invalid = [];
      if (!this.scheduleData.date && !this.scheduleData.checkRecurrence) {
        invalid.push("Schedule date is required");
      }
      if (this.scheduleData.start_end_dates && this.scheduleData.start_end_dates.length < 2 && this.scheduleData.checkRecurrence) {
        invalid.push("Schedule start/end date is required");
      }
      if (!this.scheduleData.start_time) {
        invalid.push("Schedule start time is required");
      }
      if (!this.scheduleData.end_time) {
        invalid.push("Schedule end time is required");
      }
      if (!this.scheduleData.currentMode) {
        invalid.push("Schedule mode is required");
      }
      if (invalid.length > 0) {
        this.$refs.modalMessage.open(invalid.join("<br>"));
        return false;
      }
      return true;
    },
    checkOptionSaveRecurring(){
      if(this.scheduleData && this.scheduleData.recurring_id && this.scheduleData.option === "this_event"){
        this.recurring_title = "Edit Recurring Event";
        this.isEditRecurring = true;
        this.showModalRecurringEvent = true;
      }else{
        this.saveSchedule();
      }
    },
    disableOptions(){
      let myStartTime = moment(this.scheduleData.checkMyStart);
      let myNowTime = moment();
      let fiveMinutesComplete = (moment().add(5, 'minutes'));
      return this.scheduleData.status === 'On-going' || myStartTime.isBetween(myNowTime, fiveMinutesComplete)
    },
    async saveSchedule() {
      if (this.checkInvalidData()) {
        let _start_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[0] ? moment(this.scheduleData.start_end_dates[0]) : '';
        let _end_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[1] ? moment(this.scheduleData.start_end_dates[1]) : '';
        let _date = this.scheduleData.date ? moment(this.scheduleData.date) : '';
        let data = {
          group_id: this.activeCourt.id,
          date: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) : _date.format(this.DATE_FORMAT_SCHEDULE),
          start_time: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00",
          end_time: this.scheduleData.checkRecurrence ? _end_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00",
          mode: this.scheduleData.currentMode,
          recurring_id: this.scheduleData.recurring_id ? this.scheduleData.recurring_id : null,
          option: this.scheduleData.option,
          holiday: this.scheduleData.holiday,
        };
        if (this.scheduleData.status !== 'New') {
          data.id = this.scheduleData.id;
        }
        if (this.checkTimeInvalid(data)) {
          let overlap = false;
          if((!this.scheduleData.checkRecurrence && this.scheduleData.status === 'New') || (!data.recurring_id && this.scheduleData.status !== 'New')){
            let schedulesInDay = await this.getSchedulesInDay(moment(data.date));
            let _e_start = moment(data.start_time);
            let _e_end = moment(data.end_time);
            overlap = this.checkOverLapSchedules(data.id, data.recurring_id, _e_start, _e_end, schedulesInDay);
          }
          if (!overlap) {
            if (this.scheduleData.status !== 'New' && (!data.recurring_id && data.id || data.recurring_id)) { 
              if(data.recurring_id === null){
                data.recurring_id = 0;
              }
              await this.checkHolidayExist(data)
              if(this.holidayExistData){
                data.holiday = true;
              }
              await this.update(data);
            } else {
              if(data.recurring_id === null){
                data.recurring_id = 0;
              }
              await this.checkHolidayExist(data)
              if(this.holidayExistData){
                let message = '<span>The period you selected belongs to <br> public holiday. Do you want to proceed <br> creating the schedule?</span>';
                this.messageDialogHolidayConfirm = message;
                this.showModalHolidayConfirm = true;
                this.showModalRecurringEvent = false;
              }else{
                await this.addSchedule(data);
              }
            }
          }
        }
      }
    },
    async update(data) {
      if(!data.id && data.recurring_id){
        data.id = 0;
      }
      await scheduleService.updateRecurring(data.id, data).then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
          let storeMessage = res.data.message ? 'Schedule is saved successfully.' : '';
          this.$refs.modalMessage.open(storeMessage);
          this.resetForm();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.$refs.modalMessage.open(err.response.data.message);
          }
        });
      await this.getListRecurring();
      this.isEditRecurring = false;
      this.showModalRecurringEvent = false;
    },
    checkOptionRemoveRecurring(){
      if(this.scheduleData && this.scheduleData.recurring_id && this.scheduleData.option === "this_event"){
        this.recurring_title = "Remove Recurring Event";
        this.showModalRecurringEvent = true;
      }else{
        this.showModelRemove();
      }
    },
    showModelRemove() {
      let message = "<b>Are you sure you want to delete the schedule?</b> <br><br> <span style='font-size: 18px'>This item will be deleted immediately. You <br> can’t undo this action.</span>";
      let start_ = moment(this.scheduleData.checkMyStart);
      let end_ = moment(this.scheduleData.checkMyEnd);
      if (this.NOW_DATE().isBetween(start_, end_)) {
        message = "<b>Are you sure you want to delete the schedule?</b> <br><br> <span style='font-size: 18px'>The schedule is currently on-going, <br> deleting schedule will turn off the lighting.</span>";
      }
      this.messageDialogRemoveSchedule = message;
      this.showModalRemove = true;
      this.showModalRecurringEvent = false;
    },
    async removeSchedule() {
      let _start_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[0] ? moment(this.scheduleData.start_end_dates[0]) : '';
      let _end_date = this.scheduleData.start_end_dates && this.scheduleData.start_end_dates[1] ? moment(this.scheduleData.start_end_dates[1]) : '';
      let _date = this.scheduleData.date ? moment(this.scheduleData.date) : '';
      let data = {
        group_id: this.activeCourt.id,
        date: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) : _date.format(this.DATE_FORMAT_SCHEDULE),
        start_time: this.scheduleData.checkRecurrence ? _start_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.start_time + ":00",
        end_time: this.scheduleData.checkRecurrence ? _end_date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00" : _date.format(this.DATE_FORMAT_SCHEDULE) + " " + this.scheduleData.end_time + ":00",
        mode: this.scheduleData.currentMode,
        recurring_id: this.scheduleData.recurring_id ? this.scheduleData.recurring_id : null,
        option: this.scheduleData.option,
        holiday: this.scheduleData.holiday,
      };
      data.id = this.scheduleData.id ? this.scheduleData.id : 0;
      await scheduleService.deleteRecurring(data.id, data).then((res) => {
          if (res.status !== 200 && res.status !== 204) throw res;
          this.$refs.modalMessage.open("Schedule is deleted successfully.");
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.$refs.modalMessage.open(err.response.data.message);
          }
        });
      await this.getListRecurring();
      this.resetForm();
      this.showModalRemove = false;
      this.showModalRecurringEvent = false;
    },
    getDimensions() {
      let responsive_Court = document.getElementById("responsive_Court");
      if (responsive_Court && responsive_Court.offsetWidth) {
        let ratio_width = 750 / 82;
        this.width_btn_court = responsive_Court.offsetWidth / ratio_width;
        if (
          window.innerWidth < 1878 && window.innerWidth > 1000
        ) {
          this.width_btn_court = this.width_btn_court - 8;
        }
        if (
          window.innerWidth <= 1920 && window.innerHeight <= 960
        ) {
          this.width_btn_court = this.width_btn_court - 1;
        }
       
      }
      this.height_row = this.getHeightRowGanttChart();
    },
    eventClickBarMenu(e) {
      if (e && e.bar) {
        let data = JSON.parse(JSON.stringify(e.bar));
        let nowD = this.NOW_DATE();
        this.dataScheduleEvent = data;
        this.activeClick = 'schedule-db-click';
        if (this.activeCourt && this.scheduleData && ((this.activeNew && (this.scheduleData.date || this.scheduleData.start_end_dates?.length > 0 || this.scheduleData.start_time || this.scheduleData.end_time || this.scheduleData.currentMode))
         || this.scheduleData.end_time && moment(this.scheduleData.date + " " + this.scheduleData.end_time) > nowD)) 
        {
          this.showModalContinuate = true
        } else {
          this.clickBarMenu();
        }
      }
    },
    clickBarMenu() {
      let data = this.dataScheduleEvent;
      this.setBackgroundInactiveBar();
      this.setBackgroundActiveBar(data);
      this.setBackgroundActiveRowChart(data.date);
      let nowD = this.NOW_DATE();
      let _my_start = moment(data.start_time);
      let _my_end = moment(data.end_time);
      this.scheduleData = data;
      this.scheduleData.date = moment(data.date);
      this.scheduleData.dateText = moment(data.date).format("ddd, DD MMM YY");
      this.scheduleData.start_time = moment(data.myStart).format(this.HOUR_FORMAT);
      this.scheduleData.end_time = moment(data.myEnd).format(this.HOUR_FORMAT);
      this.scheduleData.currentMode = data.mode;
      this.scheduleData.recurring_id = data.recurring_id;
      this.scheduleData.option = data.option;
      this.scheduleData.checkRecurrence = data.checkRecurrence;
      this.scheduleData.holiday = data.holiday;
      this.scheduleData.id = data.id;
      let court = this.courts.filter((item) => { return item.id == data.group_id; });
      this.scheduleData.modes = court[0].modes;
      this.activeCourt = court[0];
      this.date = moment(data.date).format(this.DATE_FORMAT_SCHEDULE);
      if (_my_end > nowD) {
        if (nowD.isBetween(_my_start, _my_end)) {
          this.scheduleData.status = "On-going";
        } else if (_my_start > nowD) {
          this.scheduleData.status = "Pending";
        }
      } else {
        this.scheduleData.status = "Completed";
      }
      this.dataScheduleEvent = null;
    },
    checkOverLapSchedules(id, recurringId, start, end, dataRow = []) {
      let overlap = false;
      for (let bar of dataRow) {
        if ((!recurringId && ((id && bar.id != id) || !id)) || ((recurringId != bar.recurring_id) || (recurringId && ((id && bar.id != id) || !id)))) {
          let _bar_start = moment(bar.myStart ?? bar.start_time);
          let _bar_end = moment(bar.myEnd ?? bar.end_time);
          if (
            (_bar_start.isBefore(start) && _bar_start.isAfter(end)) ||
            (_bar_end.isAfter(start) && _bar_end.isBefore(end)) ||
            (start.isAfter(_bar_start) && start.isBefore(_bar_end)) ||
            (end.isAfter(_bar_start) && end.isBefore(_bar_end))
          ) {
            overlap = true;
            break;
          }
        }
      }
      if (overlap) {
        this.$refs.modalMessage.open("Unable to save the schedule, due to overlap with another schedule.");
        this.showModalRecurringEvent = false;
      }
      return overlap;
    },
    eventDragBar(e) {
      let data = JSON.parse(JSON.stringify(e.bar));
      this.dataScheduleEvent = data;
      let nowD = this.NOW_DATE();
      if (this.activeNew || this.scheduleData && this.scheduleData.end_time && moment(this.scheduleData.date + " " + this.scheduleData.end_time) > nowD) {
        this.activeClick = 'schedule-drag';
        this.showModalContinuate = true
      } else {
        this.stoppedDraggingBar();
      }
    },
    async stoppedDraggingBar() {
      let data = this.dataScheduleEvent;
      if(data.recurring_id){
        this.recurring_title = "Edit Recurring Event";
        this.showModalRecurringEvent = true;
        this.isDragRecurring = true;
        this.isEditRecurring = false;
        return
      }
      let type_event = "this_event"
      this.handleStoppedDraggingBar(type_event)
    },
    selectRecurringOption() {
        this.handleStoppedDraggingBar(this.scheduleData.option)
    },
    async handleStoppedDraggingBar(type_event) {
      this.isDragRecurring = false;
      let data = this.dataScheduleEvent;
      let indexRow = this.dataSchedules.findIndex((item) => {
        return item.date == data.date;
      });
      let start_now = moment().startOf("day");
      let end_now = moment().endOf("day");
      let _e_start = moment(data.myStart);
      let _e_end = moment(data.myEnd);
      let overTime = this.checkOverTime(_e_start, _e_end, start_now, end_now);
      let indexBar = this.dataSchedules[indexRow].bars.findIndex((item) => {
        return item.id == data.id;
      });
      if (overTime) {
        if (_e_start < start_now) {
          _e_start = start_now;
        }
        if (_e_end > end_now) {
          _e_end = end_now;
        }
        let overlap = this.checkOverLapSchedules(data.id, _e_start, _e_end, this.dataSchedules[indexRow].bars);
        if (overlap) {
          this.dataSchedules[indexRow].bars[indexBar].myStart =
            _e_start.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(data.start_time).format(this.HOUR_FORMAT);
          this.dataSchedules[indexRow].bars[indexBar].myEnd =
            _e_end.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(data.end_time).format(this.HOUR_FORMAT);
        } else {
          let overTime = this.checkOverTime(_e_start, _e_end, start_now, end_now);
          let dataUpdate = {
            id: data.id,
            group_id: data.group_id,
            date: data.date,
            start_time: data.date + " " + _e_start.format(this.HOUR_FORMAT) + ":00",
            end_time: data.date + " " + _e_end.format(this.HOUR_FORMAT) + ":00",
            mode: data.mode,
            option: type_event,
            recurring_id: data.recurring_id,
            holiday: data.holiday,
          };
          if(!dataUpdate.id){
            dataUpdate.id = 0
          }
          let timeInvalid = this.checkTimeInvalid(dataUpdate);
          if (overTime && timeInvalid) {
            this.dataSchedules[indexRow].bars[indexBar].myStart =
              _e_start.format(this.DATE_TIME_HOUR_FORMAT_MOMENT);
            this.dataSchedules[indexRow].bars[indexBar].myEnd = _e_end.format(
              this.DATE_TIME_HOUR_FORMAT_MOMENT
            );
            let style = this.getStyleBar(dataUpdate);
            this.dataSchedules[indexRow].bars[indexBar].ganttBarConfig.color =
              style.color;
            this.dataSchedules[indexRow].bars[
              indexBar
            ].ganttBarConfig.backgroundColor = style.backgroundColor;
            if(dataUpdate.recurring_id === null){
              dataUpdate.recurring_id = 0;
            }
            await this.checkHolidayExist(dataUpdate)
            if(this.holidayExistData){
              dataUpdate.holiday = true;
            }
            await this.update(dataUpdate);
          } else {
            this.setTimeBar(indexRow, indexBar, start_now, end_now, data.start_time, data.end_time);
          }
        }
      } else {
        this.setTimeBar(indexRow, indexBar, start_now, end_now, data.start_time, data.end_time);
      }
    },
    disableOptionsDraggable(){
      if(this.dataScheduleEvent){
        let myStartTime = moment(this.dataScheduleEvent.checkMyStart);
        let myEndTime = moment(this.dataScheduleEvent.checkMyEnd);
        let myNowTime = moment();
        let fiveMinutesComplete = (moment().add(5, 'minutes'));
        return myNowTime.isBetween(myStartTime, myEndTime) || myStartTime.isBetween(myNowTime, fiveMinutesComplete)
      }
    },
    setTimeBar(indexRow, indexBar, start_now, end_now, start_time, end_time) {
      this.dataSchedules[indexRow].bars[indexBar].myStart = start_now.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(start_time).format(this.HOUR_FORMAT);
      this.dataSchedules[indexRow].bars[indexBar].myEnd = end_now.format(this.DATE_FORMAT_SCHEDULE) + " " + moment(end_time).format(this.HOUR_FORMAT);
    },
    checkOverTime(start_time, end_time, start_now, end_now) {
      if ((start_time < start_now && end_time < start_now) || (start_time > end_now && end_time > end_now)) {
        this.$refs.modalMessage.open("The schedule cannot be changed to another day!");
        return false;
      }
      return true;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getDimensions);
  },
};
</script>

<style scoped>
.v-text-field__details {
  display: none;
}
.g-gantt-bar-handle-right {
  background: none !important;
}

/* Model message */
  #profile-confirm.dialog_content{
    background-color: #111111; 
    border-radius: 0 !important; 
    border: 1px solid #b1b2b3;
  }

  #profile-confirm.dialog_content .v-card__title{
    padding: 12px 24px;
  }

  #profile-confirm.dialog_content .v-card__text{
    margin-top: 0;
    padding: 10px 24px 34px;
  }

  #profile-confirm.dialog_content .v-card__text .message_content{
    padding-left: 30px; 
    padding-right: 30px; 
    text-align: center;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content{
    text-align: center;
    padding-top: 40px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black{
    background-color: #010101;
    border-color: #b1b2b3;
    border-radius: 0;
    padding: 8px 16px;
    min-width: 120px;
  }

  #profile-confirm.dialog_content .v-card__text .close_btn_content .btn_black:hover{
    background-color: #292929;
  }

  @media only screen and (max-width: 3440px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(85%);
    }
  }
  @media only screen and (max-width: 2440px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(40%);
    }
  }
  @media only screen and (max-width: 1920px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(22%);
    }
  }
  @media only screen and (max-width: 1680px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(10%);
    }
  }
  @media only screen and (max-width: 1400px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(45%);
    }
  }
  @media only screen and (max-width: 1024px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(15%);
    }
  }
  @media only screen and (max-width: 810px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(30%);
    }
  }
  @media only screen and (max-width: 600px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(15%);
    }
  }
  @media only screen and (max-width: 520px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(0);
    }
  }
  @media only screen and (max-width: 460px) {
    .v-menu__content.menuable__content__active {
      transform: translateX(-30%);
    }
  }
</style>
